<template>
  <div class="chat-interface">
    <div class="chat-wrapper" :class="{ active: isChatOpen }">
      <div class="chat-content">
        <header class="chat-header">
          <img src="@/assets/white-logo.svg" width="150" alt="logo" v-if="showLogo" />
          <!-- Removed token input -->
        </header>

        <div class="chat-body" id="chat-body">
          <div class="chat-message" v-for="(message, index) in messages" :key="index" :class="message.sender">
            <small>{{ message.sender === 'me' ? 'You' : 'Model' }} - {{ formatMessageTime(message.datetime) }}</small>
            <p>{{ message.content }}</p>
          </div>

          <div v-if="isLoading" class="chat-message bot">
            <p>Loading...</p>
          </div>
        </div>

        <footer class="chat-footer">
          <input class="message-input" type="text" v-model="currentMessage" placeholder="Type a message..."
            @focus="isInputFocused = true" @blur="isInputFocused = false" @keydown.enter="sendMessage" />

          <label @click="sendFeedback(true)" class="like">
            <img src="@/assets/like.svg" alt="like" width="24" />
          </label>
          <label @click="sendFeedback(false)" class="dislike">
            <img src="@/assets/like.svg" alt="dislike" width="24" style="transform: rotate(180deg);" />
          </label>
        </footer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import axios from "axios"; // Import axios for API requests

const isChatOpen = ref(true);
const currentMessage = ref("");
const token = ref(""); // Token for API requests
const isInputFocused = ref(false);
const isLoading = ref(false);
const messages = ref([]);
const selectedOption = ref("doc");
const showLogo = ref(true);

const scrollToBottom = () => {
  const chatBody = document.getElementById("chat-body");
  if (chatBody) chatBody.scrollTop = chatBody.scrollHeight;
};

const formatMessageTime = (datetime) => {
  return datetime.toLocaleString();
};

const sendMessage = async () => {
  if (!currentMessage.value.trim()) return;

  messages.value.push({
    sender: "me",
    content: currentMessage.value,
    datetime: new Date(),
  });

  const message_content = currentMessage.value;
  currentMessage.value = "";
  scrollToBottom();

  isLoading.value = true;

  // Set search parameters based on dropdown selection
  const do_similarity_search = selectedOption.value === "doc" || selectedOption.value === "both";
  const do_numerical_search = selectedOption.value === "num" || selectedOption.value === "both";

  try {
    if (!token.value) {
      throw new Error("Empty token");
    }

    const response = await axios.get(`https://fastapi.addin.brokvisor.com/users/chat`, {
      params: {
        question: message_content,
        do_similarity_search,
        do_numerical_search,
        feedback_is_positive: false
      }, // Sending user message
      headers: {
        Authorization: `Bearer ${token.value}`, // Include Bearer token
      },
    });

    // Add the bot's response to messages
    messages.value.push({
      sender: "bot",
      content: response.data, // Assuming the response has an 'answer' field
      datetime: new Date(),
    });
  } catch (error) {
    // console.error("Error sending message:", error.message);

    let default_message = "❌ An error occurred. Please make sure you have entered a valid token and try again later."

    if (error.message === "Empty token") {
      default_message = "Please pass a valid token in the URL to start chatting. Format: https://chat.brokvisor.com/?token=YOUR_API_TOKEN";
    }

    // Push an error message to messages
    messages.value.push({
      sender: "bot",
      content: default_message,
      datetime: new Date(),
    });
  } finally {
    isLoading.value = false;
    scrollToBottom();
  }
};

const sendFeedback = async (positive) => {

  isLoading.value = true;

  messages.value.push({
    sender: "me",
    content: positive ? "👍" : "👎",
    datetime: new Date(),
  });
  scrollToBottom();

  try {
    const response = await axios.get(`https://fastapi.addin.brokvisor.com/users/chat`, {
      params: {
        question: "",
        feedback_is_positive: positive
      }, // Sending feedback
      headers: {
        Authorization: `Bearer ${token.value}`, // Include Bearer token
      },
    });

    // Add the bot's response to messages
    messages.value.push({
      sender: "bot",
      content: response.data, // Assuming the response has an 'answer' field
      datetime: new Date(),
    });
  } catch (error) {
    // console.error("Error sending feedback:", error.message);

    // Push an error message to messages
    messages.value.push({
      sender: "bot",
      content: "❌ An error occurred. Please try again later.",
      datetime: new Date(),
    });
  } finally {
    isLoading.value = false;
    scrollToBottom();
  }
};

const handleClickOutside = (event) => {
  if (!event.target.closest(".chat-interface") && isChatOpen.value) {
    isChatOpen.value = false;
  }
};

const validateToken = async () => {

  if (!token.value) {
    return;
  }

  try {
    const response = await axios.get(`https://fastapi.addin.brokvisor.com/verify_token`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (response.status !== 200) {
      token.value = "";
      throw new Error("Invalid token");
    }

    const permissions = response?.data?.permissions;

    // const permissions = ["chat-numerical-search", "chat-full-search"];

    if (permissions.includes("chat-full-search")) {
      selectedOption.value = "both";
    }
    else if (permissions.includes("chat-numerical-search")) {
      selectedOption.value = "num";
    }
    // console.log("User permissions:", permissions);
    // console.log("Selected option:", selectedOption.value);
  } catch (error) {
    console.error("Error validating token:", error.message);
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);

  // Read token from URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  token.value = urlParams.get("token") || "";
  let color = urlParams.get("color") || "004851";
  const showLogoParam = urlParams.get("logo");
  if (showLogoParam === "false") {
    showLogo.value = false;
  } else {
    showLogo.value = true;
  }
  document.querySelector(".chat-header").style.backgroundColor = `#${color}`;
  document.querySelector(".chat-footer").style.backgroundColor = `#${color}`;

  // If token has a value, try to make a request to validate it and get user permissions
  if (token.value) {
    validateToken();
  }
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

watch(() => isChatOpen.value, scrollToBottom);
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.chat-footer .search-option {
  margin-right: 12px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 4px;
}

html,
body {
  min-height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.chat-content {
  min-height: 80%;
  max-height: 80%;
}

.chat-header {
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 60px;
  background-color: #004851;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.chat-body {
  border: 1px solid #e0e0e0;
  height: calc(100vh - 136px);
  padding: 16px;
  overflow-y: auto;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.chat-message {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.chat-message p {
  border-radius: 4px;
  padding: 8px 12px;
  font-family: 'Roboto', sans-serif;
  margin-top: 8px;
}

.chat-message.me p {
  background-color: #dfe9f2;
}

.chat-message.bot p {
  background-color: rgb(250, 244, 237);
}

.chat-message small {
  margin-left: 4px;
}

.chat-footer {
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 60px;
  background-color: #004851;
  color: white;
}

.chat-footer input {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 4px;
  margin-right: 12px;
  font-family: 'Roboto', sans-serif;
}

.chat-footer .like {
  cursor: pointer;
}

.chat-footer .dislike {
  cursor: pointer;
  margin-right: 14px;
}
</style>
